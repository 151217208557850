import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, ListGroup } from "react-bootstrap"
import { firestore, useFirestoreQuery, useAuth } from "gatsby-theme-firebase";

import Layout from "../components/layout"

const Admin = () => {
  const [lists, isLoading] = useFirestoreQuery(
    firestore.collection("lists"),
  );

  const { isAuthLoading, isLoggedIn, profile } = useAuth();

  return (
    <Layout pageInfo={{ pageName: "admin" }}>
      <Container className="text-center">
        { isLoggedIn ? (
          <>
          <Row>
            <Col>
              <p>Message Groups:</p>
            </Col>
          </Row>
          <Row className="justify-content-center my-3">
            <Col md="6">
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <ListGroup>
                    {lists.map(list => list && 
                        <ListGroup.Item >
                            <Link to={`/list/`} state={{ listId : `${list._id}` }}>{list.name}</Link>
                        </ListGroup.Item>)
                    }
                    <ListGroup.Item >
                      <Link to={`/travelparty/`}>Travel Party</Link>
                    </ListGroup.Item>
                </ListGroup>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Contacts</p>
            </Col>
          </Row>
          <Row className="justify-content-center my-3">
            <Col md="6">
              <ListGroup>
                <ListGroup.Item>
                  <Link to='/app/newUser' >Add Contact</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link to='/app/allUsers' >View All Contacts</Link>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          </>
        ) : ( <><Row><Col>Please <Link to='/' >login</Link> to access this page.</Col></Row></> ) }
      </Container>
    </Layout>
  )
}

export default Admin
